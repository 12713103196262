html {
  overflow-y: scroll;
}
html, body {
  background-color: #eaeaea;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: calc(12px + .3vh + .1vw);
  line-height: 1.3;
  min-height: 100%;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
code {
  background-color: #ddd;
  display: inline-block;
  margin: 1px 0;
}
hr {
  border: 0;
  border-bottom: 1px dashed #e8e8e8;
  background: #5e5e5e;
}
.strike {
  text-decoration: line-through;
}

#wrapper {
  display: flex;
  flex-direction: column;
  font-family: monospace;
  width: 880px;
  margin: 0 auto;
  margin-bottom: 60px;
  padding: 0 10px;
}
#wrapper a {
  color: #000;
}

/* Header and nav */
#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 1.1;
  padding-top: 10px;
  margin-bottom: 45px;
}

#logo {
  flex: 1;
}
#web-perf:hover {
  color: red;
}
#web-perf:hover #just-web {
  color: #000;
  text-decoration: line-through;
}

nav {
  display: flex;
  justify-content: flex-end;
  .nav-item {
    padding: 0 15px;
    a {
      text-decoration: none;
    }
    a:hover {
      -webkit-text-decoration: underline solid #333;
      text-decoration: underline solid #333;
    }
  }
}
#nav-selected {
  -webkit-text-decoration: underline double #000;
  text-decoration: underline double #000;
}

/* Content and body */
@keyframes flashing {
  50% {
    opacity: 0;
  }
}
#flash {
  animation: flashing 1s step-start infinite;
}

#content {
  h1 {
    font-size: calc(24px + 1.0vh);
    margin-bottom: 25px;
  }
  h2:before {
    content: '> ';
  }
  h2 {
    font-size: calc(17px + .8vh);
    margin-bottom: 10px;
    margin-top: 20px;
  }
  p {
    padding: 12px 0;
  }
  p.post-link {
    padding: 0px 0;
  }
}

/* Posts */
.collection-posts {
  ol, ul {
    list-style-type: square;
    padding-left: 15px;
  }
  .footnotes ol {
    list-style-type: decimal;
  }
  li {
    padding-bottom: 5px;
  }
  .post-image-container {
    color: #333;
    font-size: calc(12px + .2vh);
    overflow-x: auto;
    text-align: center;

    .post-image {
      display: inline-block;
      margin: 0 auto;
    }
    .post-image.single-image {
      width: 70%;
    }
    .post-image.double-image {
      width: 40%;
    }

    em {
      display: block;
    }
  }
}

#content.collection-posts .footnotes p {
  padding: 0;
}

.collection-blog_categories {
  ul {
    list-style-type: square;
    padding-left: 15px;
  }
}

.tall-code {
  div.highlight {
    max-height: 10000px;
  }
}

div.highlight {
  max-height: 450px;
  overflow-y: scroll;

  code {
    background-color: transparent;
  }
}

div.highlight::-webkit-scrollbar {
  width: 9px;
}
div.highlight::-webkit-scrollbar-track {
  background: transparent;
}
div.highlight::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

/* Categories listing */
#category-listing {
  ul {
    list-style-type: square;
    padding-left: 15px;
  }
}

/* Mobile */
@media all and (max-width: 880px) {
  #wrapper {
    width: 90%;
  }
}

@media all and (max-width: 550px) {
  body {
    line-height: 1.4;
  }
  #logo {
    flex: 2;
  }
  nav {
    flex: 1;
    flex-direction: column;
  }
  .nav-item {
    flex: 1;
    margin: 4px 0;
    text-align: center;
  }

  .post-image.single-image {
    width: 100% !important;
  }
  .post-image.double-image {
    width: 100% !important;
  }
  div.highlight {
    font-size: calc(12px + .1vh);
  }
}

/* Media */
#media-icons {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  img {
    margin: 0 8px;
  }
}
