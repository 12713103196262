/* Put the whole stats container in the corner */
#stats-container {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

@media all and (min-width: 1280px) {
  #stats-container {
    bottom: 0;
    position: sticky;
    right: 0;
  }
}

/* FPS meter */
#fps {
  border: 1px solid #eee;
  display: inline-flex;
  height: 45px;
  position: relative;
  width: 100px;
}

#fps-text {
  bottom: 0;
  color: #333;
  font-family: monospace;
  position: absolute;
  right: 5px;
}

.fps-bar {
  border-top: 2px solid #444;
  flex-direction: column;
  margin-top: auto;
  width: 3px;
}

/* Page load stats */
#stats {
  display: inline-block;
  font-family: monospace;
  font-size: 10px;
  height: 45px;
  width: 80px;
}
#stats.no-stats {
  /* Safari, lol. */
  display: none;
}
